import axios from 'axios';
import router from '../router';
import store from "../store";

let config = {
    baseURL: `/api/`
};

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        return response.data.data ? response.data.data : response.data;
    },
    error => {
        error.errors = null;
        if (error.response.status === 403) {
            window.location = '/login';
        } else if (error.response.status === 422) {
            error.errors = error.response.data;
            error.errors['statusCode'] = error.response.status;
        } else if (error.response.status >= 400 || error.response.status < 500) {
            store.dispatch('setShowNotFound', true).then();
        }
        throw error.errors;
    }
);

export default httpClient;
