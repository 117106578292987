import apiClient from "./ApiService";
import axios from "axios";
import handleApiError from "@/services/handleApiError";


export default {
    getMonth(month, year, category_id) {
        return apiClient.get('event/month', { params: { month: month, year: year, category_id: category_id } })
    },
    getEvents(day, month, year) {
        return apiClient.get('event/list', { params: { day: day, month: month, year: year } })
    },
    getEvent(id) {
        return apiClient.get('event', { params: { id: id } })
    },
    createEvent(form) {
        return axios.post('/api/event/propose', form).then(resp => resp.data.data).catch(handleApiError);
    },
    getEventCategories() {
        return axios.get('/api/event/categories').then(resp => resp.data.data.items).catch(handleApiError);
    }
}
